import { getImage, StaticImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/mvp-web-development/Anchors'
import MvpWebDevBenefits from '~/mock/services/mvp-web-development/MvpWebDevBenefits'
import MvpWebDevBusinessType from '~/mock/services/mvp-web-development/MvpWebDevBusinessType'
import MvpWebDevExpertise from '~/mock/services/mvp-web-development/MvpWebDevExpertise'
import MvpWebDevFAQ from '~/mock/services/mvp-web-development/MvpWebDevFAQ'
import MvpWebDevIndustries from '~/mock/services/mvp-web-development/MvpWebDevIndustries'
import MvpWebDevPractices from '~/mock/services/mvp-web-development/MvpWebDevPractices'
import MvpWebDevProcess from '~/mock/services/mvp-web-development/MvpWebDevProcess'
import MvpWebDevServices from '~/mock/services/mvp-web-development/MvpWebDevServices'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import useMvpWebDevelopmentStaticQuery from '~/views/Services/MvpWebDevelopment/useMvpWebDevelopmentStaticQuery'

import * as containerStyles from './MvpWebDevelopment.module.scss'

const clientName = 'David Watkins'

const MvpWebDevelopment = () => {
  const query = useMvpWebDevelopmentStaticQuery()
  const clientRef = getImage(fileToImageLikeData(query.clientRef))
  const portfolioPMK = getImage(fileToImageLikeData(query.portfolioPMK))

  return (
    <MainLayout scrollTop>
      <section className={containerStyles.mvpWebDevPrimeSection}>
        <PrimeSectionServices
          titleTop="MVP Web Development"
          title="Company"
          description="Codica is a leading web development company that creates top minimum viable products (MVPs) for startups and businesses. With our skilled team, we offer customized web solutions tailored to the clients’ needs."
          breadcrumbSecondLevel="Services"
          breadcrumbSecondLevelPath="/services/"
          breadcrumbCurrent="MVP Web Development Company"
          breadcrumbCurrentPath="/services/mvp-web-development/"
        />
      </section>

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.mvpWebDevServicesSection}
      >
        <HeadSection
          position="center"
          title="MVP web development services we offer"
          description="We are committed to delivering top-notch MVP website development services that help our clients launch their products quickly, gather user feedback, and iterate for future enhancements."
          titleLarge
          descriptionLarge
        />
        <Service3DIconCard dataContent={MvpWebDevServices} />
      </section>

      <section
        id="industries"
        className={containerStyles.mvpWebDevIndustriesSection}
      >
        <HeadSection
          position="center"
          title="Main industries we serve"
          description="With a track record of delivering successful MVP products for diverse businesses globally, our team has gained deep cross-domain expertise. We bring it to each new project we undertake."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={MvpWebDevIndustries} />
      </section>

      <CallToAction
        title="Ready to turn your app idea into a reality?"
        subtitle="Let’s build a compelling MVP together."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="business-type"
        className={containerStyles.mvpWebDevBusinessTypeSection}
      >
        <HeadSection
          position="center"
          title="Types of MVP web development"
          description="Through our MVP development process, you will gain a foundational web solution that can be further expanded into a fully-functional product. The MVP model is highly adaptable and suitable for enterprises of any type."
          descriptionLarge
        />
        <ServicesIndustriesCard isColTwo dataContent={MvpWebDevBusinessType} />
      </section>

      <section className={containerStyles.mvpWebDevProcessSection} id="process">
        <HeadSection
          position="center"
          title="Our process of MVP web development"
          description="Utilizing our streamlined desktop and mobile web development MVP process, we efficiently craft top-notch software within a condensed timeframe. The creation of a minimum viable product includes:"
          titleLarge
          descriptionLarge
        />
        <ServicesProcess dataContent={MvpWebDevProcess} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.mvpWebDevTechStackSection}
      >
        <HeadSection
          position="center"
          title="Tech stack we are sure for"
          description="At Codica, we stay up-to-date with the latest trends and advancements in web development to pick the most suitable technologies for each MVP project."
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section
        id="benefits"
        className={containerStyles.mvpWebDevBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Benefits of MVP web development"
          description="The MVP approach stands as the optimal method for developing new products. MVP entails constructing a website incorporating just enough features to satisfy initial users. This allows for the rapid development and launch of the core product, significantly reducing time-to-market and expenses."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={MvpWebDevBenefits} />
        <SubtractBlock />
      </section>

      <CallToAction
        title="Want to unveil your MVP’s full potential?"
        subtitle="We craft MVPs that stand out in the competitive landscape."
        classNameForGA="servicesCTA_2"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="practices"
        className={containerStyles.mvpWebDevPracticesSection}
      >
        <HeadSection
          position="center"
          title="Why choose Codica for MVP web development"
          description="Selecting Codica for your MVP web development means partnering with a reliable and experienced team that is dedicated to delivering exceptional results."
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={MvpWebDevPractices} />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.mvpWebDevProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <section className={containerStyles.mvpWebDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Codica’s experience"
          description="At Codica, our journey in MVP web development captures successes that have shaped exciting projects and the best industry standards. With each project, we’ve honed our craft, navigating the dynamic landscape of web development with innovation and precision."
          descriptionLarge
        />
        <ServicesDevExpertiseCompany dataContent={MvpWebDevExpertise} />
      </section>

      <section className={containerStyles.mvpWebDevCaseSection} id="cases">
        <HeadSection position="center" title="Success case study" />
        <DiscoverWorksItem
          key={3}
          gradient="gradientPmkDesktop"
          image={portfolioPMK}
          header="Online Service Marketplace"
          title="PlanMyKids is an online service enabling parents to plan and book kids’ activities in nearby locations. The marketplace provides a simple solution that connects parents with camps and after-school programs."
          resultsLeft="Developed a scalable MVP on time and under budget."
          resultsRight="Build a convenient and intuitive online booking platform."
          link="kids-activities-service-marketplace"
          review={[
            '“Working with the Codica team has been a great experience. Our team spent a good time working with a parent focus group to narrow down our MVP features which Codica brought to life through prototypes.',
            <div key="featuredCase" style={{ paddingTop: 10 }} />,
            'In the end, Codica delivered our marketplace MVP under budget, which for a bootstrapped company was important.”',
          ]}
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/clients/client-pmk.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Founder of PlanMyKids"
        />
      </section>

      <section className={containerStyles.mvpWebDevClientQuotes}>
        <HeadSection
          position="center"
          title="Clients say about us"
          description="Our clients prefer collaborating with us for their profitable digital projects."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Leon Zinger"
            location="Israel"
            position="Founder at RefDental"
            image={clientRef}
            quote={[
              {
                text: '“What we like most about working with Codica is their deep understanding of our business needs. They quickly grasped our initial idea and delivered our Minimum Viable Product within the specified time and budget. Thanks to this, we could get user feedback fast”',
              },
            ]}
            link="dental-imaging-management-system"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build a dream web solution together!"
          widthTitle="800px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our web app specialists will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={MvpWebDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default MvpWebDevelopment
