// extracted by mini-css-extract-plugin
export var mvpWebDevBenefitsSection = "y_dX";
export var mvpWebDevBusinessTypeSection = "y_dT";
export var mvpWebDevCaseSection = "y_d1";
export var mvpWebDevClientQuotes = "y_d2";
export var mvpWebDevExpertiseSection = "y_d0";
export var mvpWebDevIndustriesSection = "y_dS";
export var mvpWebDevPracticesSection = "y_dY";
export var mvpWebDevPrimeSection = "y_dQ";
export var mvpWebDevProcessSection = "y_dV";
export var mvpWebDevProjLogoSection = "y_dZ";
export var mvpWebDevServicesSection = "y_dR";
export var mvpWebDevTechStackSection = "y_dW";